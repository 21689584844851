import { Component, Vue } from "vue-property-decorator";
import { formatMoney, getSymbolCurrency } from "@helpers";
import { ProfileGetters } from "@store/modules/profile/types";
import { WalletGetters } from "@store/modules/wallet/types";
import { profileModule, walletModule, userModule } from "@store/namespaces";
import { Currency } from "@/types/currency";
import { Role } from "@/types/role";
import { hasRole } from "@/lib/role";
import { UserGetters } from "@store/modules/user/types";
import { HtmlElementId } from "@/types/element";

interface Item {
  currency: Currency;
  balance: number;
  cardBalance: number;
  currencyColor?: string;
  bgColor?: string;
}

@Component
export default class HeaderWalletsMixin extends Vue {
  @walletModule.Getter("walletsList")
  private readonly walletsListGetter!: WalletGetters["walletsList"];
  @profileModule.Getter("profileAuthority")
  private readonly profileAuthorityGetter!: ProfileGetters["profileAuthority"];
  @userModule.Getter("userIsGhost")
  private readonly userIsGhostGetter!: UserGetters["userIsGhost"];

  private get disabledRefill() {
    return (
      !hasRole(this.profileAuthorityGetter, [
        Role.ROLE_TEAMLEAD,
        Role.ROLE_OWNER,
      ]) ||
      this.userIsGhostGetter ||
      this.$route.name === "refill"
    );
  }

  private get htmlElementId() {
    return {
      headerWallets: HtmlElementId.headerWallets,
    };
  }

  protected get items(): Item[] {
    const extendWallet = (currency: Currency) => {
      switch (currency) {
        case Currency.USD:
          return {
            currencyColor: "#19AC4F",
            bgColor: "#E7FAEE",
          };

        case Currency.EUR:
          return {
            currencyColor: "#2E79E1",
            bgColor: "#E6EEF9",
          };

        case Currency.TRY:
          return {
            currencyColor: "#A369B7",
            bgColor: "#F3E6F8",
          };

        default:
          return {};
      }
    };

    return this.walletsListGetter.map((wallet) => ({
      ...extendWallet(wallet.currency),
      ...wallet,
    }));
  }

  private getSymbolCurrency(val: Currency) {
    return getSymbolCurrency(val);
  }

  private formatMoney(value: number, currency: Currency) {
    return formatMoney({
      value,
      currency,
      showSymbol: true,
      maximumFractionDigits: 2,
    });
  }
}
