var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    'wallet d-flex',
    `wallet_${_vm.currency.toLowerCase()}`,
    {
      wallet_rounded: _vm.rounded,
    },
  ],style:({
    '--currency-color': _vm.currencyColor,
    '--bg-color': _vm.bgColor,
    '--bg-color-1': '#E7FAEE',
  })},[(!_vm.isMobile)?_c('v-tooltip',{attrs:{"open-on-focus":false,"open-on-click":false,"open-on-hover":_vm.$vuetify.breakpoint.lgAndUp,"disabled":_vm.disabledRefill,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({class:[
          'wallet__currency align-self-center flex-shrink-0',
          { wallet__currency_disabled: _vm.disabledRefill },
        ]},'div',attrs,false),on),[_c('v-btn',_vm._g(_vm._b({staticClass:"wallet__currency-btn",attrs:{"to":!_vm.disabledRefill ? { name: 'refill' } : undefined,"height":_vm.currencySize,"width":_vm.currencySize,"ripple":false,"text":"","fab":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.getSymbolCurrency(_vm.currency))+" ")])],1)]}}],null,false,1483690884)},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.dashboard.to_refill"))+" ")]):_c('div',{staticClass:"wallet__currency align-self-center flex-shrink-0"},[_c('v-btn',{staticClass:"wallet__currency-btn",attrs:{"to":!_vm.disabledRefill ? { name: 'refill' } : undefined,"height":_vm.currencySize,"width":_vm.currencySize,"ripple":false,"text":"","fab":""}},[_vm._v(" "+_vm._s(_vm.getSymbolCurrency(_vm.currency))+" ")])],1),_c('div',{class:['wallet__content text-no-wrap', _vm.contentClass],on:{"click":_vm.onClickContent}},[_c('p',{staticClass:"wallet__label"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.dashboard.table.header.balance"))+" ")]),_c('span',{staticClass:"wallet__value"},[_vm._v(" "+_vm._s(_vm.formatMoney(_vm.balance, _vm.currency))+" ")]),_c('p',{staticClass:"wallet__label"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.dashboard.table.header.card_balance"))+" ")]),_c('span',{staticClass:"wallet__value"},[_vm._v(" "+_vm._s(_vm.formatMoney(_vm.cardBalance, _vm.currency))+" ")])]),_vm._t("append")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }