import { Component, Vue, Prop } from "vue-property-decorator";
import { formatMoney, getSymbolCurrency } from "@helpers";
import { Currency } from "@/types/currency";

@Component
export default class Wallet extends Vue {
  @Prop({ type: String, required: true })
  private readonly currencyColor!: string;
  @Prop({ type: String, required: true })
  private readonly bgColor!: string;
  @Prop({ type: String, required: true })
  private readonly currency!: Currency;
  @Prop({ type: Number, required: true })
  private readonly balance!: number;
  @Prop({ type: Number, required: true })
  private readonly cardBalance!: number;
  @Prop({ type: Boolean, default: false })
  private readonly disabledRefill!: boolean;
  @Prop({ type: Boolean, default: true })
  private readonly rounded!: boolean;
  @Prop({ type: String, default: "" }) private readonly contentClass!: string;

  private get isMobile() {
    return this.$vuetify.breakpoint.mobile;
  }

  private get currencySize() {
    if (this.isMobile) {
      return 32;
    }

    return 40;
  }

  private getSymbolCurrency(val: Currency) {
    return getSymbolCurrency(val);
  }

  private formatMoney(value: number, currency: Currency) {
    return formatMoney({
      value,
      currency,
      showSymbol: true,
      maximumFractionDigits: 2,
    });
  }

  private onClickContent() {
    this.$emit("click:content");
  }
}
