import { Component, Mixins } from "vue-property-decorator";

import HeaderWalletsMixin from "../../common/mixins/header-wallets.mixin";
import Wallet from "../../common/components/Wallet/Wallet.vue";

@Component({
  components: {
    Wallet,
  },
})
export default class DesktopHeaderWallets extends Mixins(HeaderWalletsMixin) {}
